import { intersection } from 'ramda';
import eventsOverviewUrlService from 'src/app/pages/events/eventsOverviewUrlService';
import urlService from 'src/app/services/urlService';
import { ETBAAS_ROLES, Roles } from 'src/data/enums/roles';

export interface UserRoleConfig {
    etbaas: boolean;
    eTicketManagement: boolean;
    administrator: boolean;
    redirectUrl: string;
}

const adminRole = [Roles.ADMINISTRATOR];
const etbaas = ETBAAS_ROLES;
export const eticketManagementRole = [...adminRole, Roles.TICKET_MANAGEMENT];

const getIsAllowedToEtbaas = (userRoles: Roles[]) => !!intersection(etbaas, userRoles).length;

const getIsAllowedToTicketManagement = (userRoles: Roles[]) =>
    !!intersection(eticketManagementRole, userRoles).length;

const getIsAdministrator = (userRoles: Roles[]) => !!intersection(adminRole, userRoles).length;

export const allowTo = (userRoles: Roles[]): UserRoleConfig => {
    const etbaas = getIsAllowedToEtbaas(userRoles);
    const eTicketManagement = getIsAllowedToTicketManagement(userRoles);
    const administrator = getIsAdministrator(userRoles);

    const getRedirectUrl = (): string => {
        if (etbaas) return eventsOverviewUrlService.root();
        if (eTicketManagement) return urlService.getTicketManagement();
        return urlService.getNotAuthorized();
    };

    return {
        etbaas,
        eTicketManagement,
        administrator,
        redirectUrl: getRedirectUrl(),
    };
};

export const hasPermission = (rolesRequested: Roles[], userRoles: Roles[]): boolean => {
    const isAllowedTo = allowTo(userRoles);

    if (userRoles.includes(Roles.EMPLOYEE)) return true;

    if (rolesRequested.includes(Roles.TICKET_MANAGEMENT)) return isAllowedTo.eTicketManagement;

    return isAllowedTo.etbaas;
};

export const hasOneOfRoles = (rolesToFind: Roles[], userRoles: Roles[]) => {
    let hasRoles = false;

    rolesToFind.forEach((roleTofind) => {
        if (hasRoles) return;

        hasRoles = !!userRoles.find((r) => r === roleTofind);
    });

    return hasRoles;
};
