export enum Roles {
    ADMINISTRATOR = 'Administrator',
    ACCOUNT_LEAD = 'AccountLead',
    ACCOUNT_SENIOR = 'AccountSenior',
    ACCOUNT_JUNIOR = 'AccountJunior',
    ACCOUNT_INTERN = 'AccountIntern',
    CUSTOMER_CARE_LEAD = 'CustomerCareLead',
    CUSTOMER_CARE_SENIOR = 'CustomerCareSenior',
    CUSTOMER_CARE_JUNIOR = 'CustomerCareJunior',
    CUSTOMER_CARE_INTERN = 'CustomerCareIntern',
    FINANCE_LEAD = 'FinanceLead',
    FINANCE_SENIOR = 'FinanceSenior',
    FINANCE_JUNIOR = 'FinanceJunior',
    FINANCE_INTERN = 'FinanceIntern',
    OPERATIONS_LEAD = 'OperationsLead',
    OPERATIONS_SENIOR = 'OperationsSenior',
    OPERATIONS_JUNIOR = 'OperationsJunior',
    OPERATIONS_INTERN = 'OperationsIntern',
    PRODUCT_LEAD = 'ProductLead',
    PRODUCT_SENIOR = 'ProductSenior',
    PRODUCT_JUNIOR = 'ProductJunior',
    PRODUCT_INTERN = 'ProductIntern',
    WEEKEND_SHIFT = 'WeekendShift',
    EMPLOYEE = 'Employee',
    // DEVELOPERS = 'Developers',
    // VENDING_MACHINE = 'VendingMachine',
    // PLATFORM_PRICER = 'PlatformPricer',
    TICKET_MANAGEMENT = 'TicketManagement',
    // INVENTORY = 'Inventory',
}

export const ALL_ROLES = Object.values(Roles);
export const ETBAAS_ROLES = Object.values(Roles).filter((role) => role !== Roles.TICKET_MANAGEMENT);
